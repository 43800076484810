import React from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";

const Header = () => {
  const handleLinkClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="header flex flex-col" id="header">
      <Navbar />
      <div className="container flex">
        <div className="header-content">
          <h2 className=" text-white op-07 fw-6 ls-2">
            AKADEMİ SANAT ORGANİZASYON
          </h2>
          <h1 className="text-white fw-6 header-title">
            Hayalinizdeki organizasyonu bize anlatın {""}
            <span className="text-brown">Gerisini biz yapalım</span>
          </h1>
          <div className="btn-groups flex">
            <Link
              onClick={(e) => handleLinkClick(e, "work")}
              className="nav-link text-white ls-1  fw-6 fs-22"
            >
              <button type="button" className="btn-item bg-brown fw-4 ls-2">
                Projelerimiz
              </button>
            </Link>
            <Link
              onClick={(e) => handleLinkClick(e, "contact")}
              className="nav-link text-white ls-1  fw-6 fs-22"
            >
              <button type="button" className="btn-item bg-dark fw-4 ls-2">
                Bize Ulaşın
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
