import React from "react";
import "./Contact.css";
import { FaWhatsapp } from "react-icons/fa";
import { CgMail } from "react-icons/cg";
import { BsFillTelephoneFill } from "react-icons/bs";
const Contact = () => {
  return (
    <div className="contact section-p" id="contact">
      <div className="container ">
        <div className="section-title">
          <h3 className="text-brown">İletişim</h3>
        </div>
        <div className="contact-section contactContainer">
          <div className="icons">
            <a
              href="tel:+05325430852"
              target="_blank"
              rel="noreferrer"
              className="text-dark flex "
            >
              <BsFillTelephoneFill className="icon" size={32} />
              <span>
                <p>+0532 543 08 52 </p>
              </span>
            </a>
          </div>
          <div className="icons">
            <a
              href="mailto:akademi_sanat@gmail.com"
              target="_blank"
              rel="noreferrer"
              className="text-dark flex"
            >
              <CgMail className="icon" size={42} />

              <p>İnfo@akademisanat.com</p>
            </a>
          </div>
          <div className="icons">
            <a
              href="https://wa.me/905325430852"
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark flex  "
            >
              <FaWhatsapp className="icon" size={36} />

              <p>WhatsApp</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
