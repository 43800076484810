import React from "react";
import "./About.css";
import images from "../../constants/images";

const About = () => {
  return (
    <div className="about section-p" id="about">
      <div className="container">
        <div className="about-content">
          <div className="about-grid grid">
            <img src={images.About_Alex} alt="" className="about-img mx-auto" />
            <div className="section-title">
              <h3 className="text-brown">Akademi Sanat Organizasyon</h3>
              <p className="text mx-auto">
                Her türlü özel gün ve eğlencelerinizde beklentilerinizin
                ötesinde sanatsal çözümler üreten Akademi Sanat Organizasyon,
                müzik grupları, gösteri ekipleri ve birbirinden özel konsept
                programları ile eğlence sektöründe tecrübeli ve güvenilir
                hizmetler sunan bir sanat organizasyonudur.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
